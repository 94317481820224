
import { Vue, Component } from "vue-property-decorator";
import {
  DocumentObjectDataSource,
  ObjectDataSource,
} from "@/data/Object/ObjecDatatSource";
import InlineFileUploader from "@/components/inline-file-uploader.vue";
import { translit } from "@/cms-services/helpers";

@Component({
  components: { InlineFileUploader },
})
export default class Home extends Vue {
  dataSource: DocumentObjectDataSource = new DocumentObjectDataSource(
    +this.$route.params.id,
  );
  $message: any;
  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Документы",
        to: "/manage/documents/",
        exact: true,
      },
      {
        text: this.dataSource.model?.caption,
        disabled: true,
      },
    ];
  }

  fileMetaData: any = {
    name: "linkedFiles",
    type: "file",
    description: null,
    caption: "Связанные файлы",
    editor: "fileuploader",
    priority: 100,
    group: "linkedcomponents",
    visibleInTable: false,
    validations: [],
    items: [
      {
        type: "txt",
      },
      {
        type: "zip",
      },
      {
        type: "pptx",
      },
      {
        type: "doc",
      },
      {
        type: "docx",
      },
      {
        type: "pdf",
      },
      {
        type: "jpeg",
      },
      {
        type: "jpg",
      },
      {
        type: "png",
      },
    ],
  };

  loading: boolean = false;

  blocksHtml: HTMLDivElement = document.createElement("div");
  currentFolderId: number = 0;
  folders: any = [];

  onMounted() {
    const blocks = this.dataSource.model?.content?.blocks?.map?.(
      (b: any) => b.model.text,
    );
    if (!blocks) return;

    this.blocksHtml.innerHTML = blocks.join("");
  }

  folderElements: string[] = ["h3"];
  fileElements: string[] = ["a"];

  async convert() {
    this.folders = [];
    this.loading = true;
    this.parseHtmlBlocks(this.blocksHtml.children);

    await this.dataSource.convert(this.folders);
    this.loading = false;
    this.$message("Структура успешно сконвертировано");
  }

  parseHtmlBlocks(blocks: HTMLCollection) {
    for (const block of Array.from(blocks) as HTMLElement[]) {
      if (this.folderElements.includes(block.localName)) {
        this.currentFolderId = this.folders.length + 1;
        this.folders.push({
          transietId: this.currentFolderId,
          caption: block.innerText,
          children: [],
          files: [],
          parentId: this.folders.transietId,
          name: translit(block.innerText),
        });
        continue;
      }

      if (this.fileElements.includes(block.localName)) {
        const a = block as HTMLLinkElement;
        const folder = this.folders.find(
          (f: any) => f.transietId == this.currentFolderId,
        );
        folder.files.push({
          fileName: a.innerText,
          url: a.href,
        });
        continue;
      }

      if (block.children.length > 0) {
        this.parseHtmlBlocks(block.children);
      }
    }
  }
}
